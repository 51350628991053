import { IconButton, TextButton } from "components/Button";
import * as Icon from "components/Icon";
import { TextField, TextFieldInputType, TextFieldWidth } from "components/TextInput/TextField";
import * as CSS from "csstype";
import { useAsyncDebounce } from "hooks/useAsyncDebounce";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { EverIdProp } from "util/type";

export interface TextFilterProps extends EverIdProp {
    /**
     * An optional class name to apply to the text button or text field.
     */
    className?: string;
    /**
     * A descriptive label to apply as the aria-label of the "Search" button and the hidden
     * label of the text field.
     */
    "aria-label": string;
    /**
     * The current value of the filter text input.
     */
    inputValue: string;
    /**
     * The setter for the {@link inputValue} state variable. This is separate from
     * {@link setFilterValue} because the input value and filter value may not be in sync if
     * there is a debounce period (see {@link debounceDelay}). When the user types into the
     * filter input, {@link inputValue} updates immediately, whereas the filter value doesn't
     * update until the debounce period has passed.
     */
    setInputValue: Dispatch<string>;
    /**
     * Whether the input should be displayed. If false, the "Search" button is displayed instead.
     */
    showInput: boolean;
    /**
     * The setter for the {@link showInput} state variable.
     */
    setShowInput: Dispatch<boolean>;
    /**
     * The setter for the filter value state variable.
     */
    setFilterValue: Dispatch<string>;
    /**
     * The placeholder text to be displayed in the filter input.
     */
    placeholder: string;
    /**
     * The width of the filter input. Defaults to {@link TextFieldWidth.STANDARD}.
     */
    width?: TextFieldWidth | CSS.Property.Width;
    /**
     * The amount of debounce delay in milliseconds. Defaults to 250.
     */
    debounceDelay?: number;
}

/**
 * A "Search" button that becomes a text field for searching/filtering with built-in
 * debounce functionality.
 */
export const TextFilter: FC<TextFilterProps> & {
    use: typeof useTextFilter;
} = ({
    everId,
    className,
    "aria-label": ariaLabel,
    inputValue,
    setInputValue,
    showInput,
    setShowInput,
    setFilterValue,
    placeholder,
    width = TextFieldWidth.STANDARD,
    debounceDelay = 250,
}) => {
    const onChange = useAsyncDebounce(setFilterValue, debounceDelay);

    return showInput ? (
        <TextField
            everId={everId}
            className={className}
            type={TextFieldInputType.SEARCH}
            autoFocus={true}
            value={inputValue}
            width={width}
            label={ariaLabel}
            hideLabel={true}
            leftIcon={<Icon.Search size={20} />}
            rightButtons={
                <IconButton
                    aria-label={"Clear filter"}
                    onClick={() => {
                        setInputValue("");
                        setFilterValue("");
                        setShowInput(false);
                    }}
                >
                    <Icon.X size={20} />
                </IconButton>
            }
            onChange={(e) => {
                setInputValue(e.target.value);
                onChange(e.target.value);
            }}
            placeholder={placeholder}
        />
    ) : (
        <TextButton
            className={className}
            icon={<Icon.Search />}
            onClick={() => setShowInput(true)}
            aria-label={ariaLabel}
        >
            Search
        </TextButton>
    );
};

interface UseTextFilterProps {
    /**
     * The initial value for {@link UseTextFilterResult#inputValue}. Defaults to "".
     */
    initialInputValue?: string;
    /**
     * The initial value for {@link UseTextFilterResult#filterValue}. Defaults to "".
     */
    initialFilterValue?: string;
    /**
     * The initial value for {@link UseTextFilterResult#showInput}. Defaults to false.
     */
    initialShowInput?: boolean;
}

export interface UseTextFilterResult {
    /**
     * The current string value for the filter input.
     */
    inputValue: string;
    /**
     * A setter for the {@link inputValue} state variable.
     */
    setInputValue: Dispatch<SetStateAction<string>>;
    /**
     * The current filter value. This is separate from {@link inputValue} because the
     * filter may be debounced.
     */
    filterValue?: string;
    /**
     * A setter for the {@link filterValue} state variable.
     */
    setFilterValue: Dispatch<SetStateAction<string>>;
    /**
     * Whether to show the filter input.
     */
    showInput: boolean;
    /**
     * A setter for the {@link showInput} state variable.
     */
    setShowInput: Dispatch<SetStateAction<boolean>>;
}

/**
 * A hook that sets up the necessary state variables and their setters for use with
 * {@link TextFilter} and returns them. This hook should be called at whatever level the state
 * of the {@link TextFilter} lives. The provided initial values will be used.
 *
 * See {@link UseTextFilterResult} for details on the variables returned by this hook.
 */
function useTextFilter(props: UseTextFilterProps = {}): UseTextFilterResult {
    const { initialInputValue = "", initialFilterValue = "", initialShowInput = false } = props;
    const [inputValue, setInputValue] = useState<string>(initialInputValue);
    const [filterValue, setFilterValue] = useState<string>(initialFilterValue);
    const [showInput, setShowInput] = useState<boolean>(initialShowInput);
    return {
        inputValue,
        setInputValue,
        filterValue,
        setFilterValue,
        showInput,
        setShowInput,
    };
}

TextFilter.use = useTextFilter;
