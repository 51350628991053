import clsx from "clsx";
import { useDetectClickOutside } from "hooks/useDetectClickOutside";
import { useCombinedRef } from "hooks/useCombinedRef";
import React, { forwardRef, Dispatch, ReactNode, useRef } from "react";
import "./Popover.scss";
import { combineSelectors } from "util/css";
import { FFC } from "util/type";
import {
    BasePopover,
    BasePopoverProps,
    POPOVER_DEFAULT_ARROW_WIDTH,
    POPOVER_DEFAULT_ARROW_HEIGHT,
    POPOVER_DEFAULT_ARROW_MARGIN,
    PopoverAlignment,
    PopoverPlacement,
    PopoverSide,
    PopoverNesting,
} from "components/util/BasePopover/BasePopover";

export {
    POPOVER_DEFAULT_ARROW_WIDTH,
    POPOVER_DEFAULT_ARROW_HEIGHT,
    POPOVER_DEFAULT_ARROW_MARGIN,
    PopoverAlignment,
    PopoverPlacement,
    PopoverSide,
    PopoverNesting,
};

export interface PopoverProps extends Omit<BasePopoverProps, "role" | "modal"> {
    /**
     * Function that sets the show state.
     */
    setShow: Dispatch<boolean>;
    /**
     * CSS selectors for which matching elements should be ignored when clicked. Normally,
     * when a click outside the popover or target is detected, the popover closes. If the
     * clicked element matches any of the {@link triggerSelectors} though, the click will
     * be ignored and the popover will remain open.
     *
     * Use this option when any elements outside the target should trigger the popover.
     * Otherwise, trying to open the popover by clicking a non-target element will cause
     * the popover to immediately close, since a click outside the target was detected.
     *
     * Use constants instead of hard-coded strings when possible to prevent selector
     * mismatches when classes are renamed.
     */
    triggerSelectors?: string[];
    /**
     * The content to display in the popover footer under a horizontal divider line.
     */
    footer?: ReactNode;
}

/**
 * Generic popover component. Has the "dialog" ARIA role.
 *
 * A popover is defined as an element that is rendered next to another element and floats above
 * other page elements (e.g. tooltips, popover menus).
 *
 * Before using this component, make sure that there isn't another design system component that
 * already uses Popover and better suits your needs.
 *
 * This component automatically handles flipping and shifting itself to fit itself within the
 * viewport if given the appropriate props.
 *
 * If the user clicks outside the Popover or presses the escape key, then the popover will
 * attempt to close itself via the passed in `setShow` function and focus the target element.
 */
export const Popover: FFC<HTMLDivElement, PopoverProps> = forwardRef<HTMLDivElement, PopoverProps>(
    ({ className, target, setShow, children, footer, triggerSelectors, ...props }, refProp) => {
        const popoverRef = useRef<HTMLDivElement>(null);
        useDetectClickOutside(popoverRef, (e) => {
            if (!props.show) {
                return;
            }
            // Ignore click events on the target as well as triggers. Target and trigger
            // elements should handle their own events.
            if (e instanceof MouseEvent) {
                if (
                    e.target instanceof Node
                    && target.current instanceof Node
                    && target.current?.contains(e.target)
                ) {
                    return;
                }
                if (
                    triggerSelectors?.length
                    && e.target instanceof Element
                    && e.target.matches(combineSelectors(triggerSelectors))
                ) {
                    return;
                }
            }
            if (
                e instanceof KeyboardEvent
                && e.key === "Escape"
                && target.current instanceof HTMLElement
            ) {
                target?.current?.focus();
            }
            setShow(false);
        });
        const finalRef = useCombinedRef(refProp, popoverRef);

        // Elements with the "dialog" role must be appropriately labeled.
        if (!props["aria-label"] && !props["aria-labelledby"]) {
            console.warn(
                "Warning: either `aria-labelledby` or `aria-label` must be provided to Popover.",
            );
        }

        return (
            <BasePopover
                ref={finalRef}
                className={clsx(className, "bb-popover--generic")}
                target={target}
                modal={true}
                {...props}
            >
                {children}
                {footer && (
                    <>
                        <hr className={"bb-popover__divider"} />
                        <div className={"bb-popover__footer"}>{footer}</div>
                    </>
                )}
            </BasePopover>
        );
    },
);
Popover.displayName = "Popover";
