import clsx from "clsx";
import * as Icon from "components/Icon";
import { ButtonSize, IconButton, TextButton } from "components/Button";
import * as CommonIcon from "components/Icon/CommonIcon";
import { IconProps } from "components/Icon/IconProps";
import { ItemDisplay } from "components/ItemDisplay";
import { PopoverMenu, PopoverMenuPlacement } from "components/Menu/PopoverMenu";
import { DetailCardProgressBarProps } from "components/ProgressBar";
import { TextVariant } from "components/Text";
import { Heading, HeadingElement, HeadingMargin } from "components/Text/Heading";
import {
    Renameable,
    TextField,
    TextFieldFontSize,
    TextFieldHeight,
    TextFieldWidth,
} from "components/TextInput";
import { TooltipPlacement, useEllipsisTooltip } from "components/Tooltip";
import { everIdProp } from "EverAttribute/EverId";
import { useBrandedCallback } from "hooks/useBranded";
import { useButtonRole } from "hooks/useButtonRole";
import { useCombinedRef } from "hooks/useCombinedRef";
import React, { Dispatch, FC, ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import "./DetailCard.scss";
import { EverIdProp } from "util/type";
import { EverColor } from "tokens/typescript/EverColor";

/**
 * Alternate job statuses that affect the appearance of the {@link DetailCard}
 */
export enum DetailCardStatus {
    DEFAULT = "default",
    ERROR = "error", // adds error icon, makes card border red
    IN_PROGRESS = "in-progress", // adds loading icon
}

export interface DetailCardProps extends EverIdProp {
    /**
     * The body of the card.
     */
    children: ReactNode;
    /**
     * An optional class name to add to the root element of the card.
     */
    className?: string;
    /**
     * The contents of the description field (under {@link title}).
     *
     * If both {@code description} and {@link setDescription} are undefined, the description
     * field will be hidden.
     */
    description?: string;
    /**
     * A section for any details or metadata about this card such as date created, size, type, etc.
     */
    detailSection?: ReactElement;
    /**
     * An optional section at the end of the card.
     */
    footer?: ReactNode;
    /**
     * The buttons displayed to the right of the title. Generally a {@code UserBadge}
     * followed by a {@code DetailCard.ThreeDotMenu}
     */
    headerButtons?: ReactNode;
    /**
     * The heading element to use for the card title.
     */
    headingElement: HeadingElement;
    /**
     * An optional ProgressBar.DetailCard.
     */
    progressBar?: ReactElement<DetailCardProgressBarProps> | null;
    /**
     * The setter for the {@link description} state. If not provided, description will be read-only.
     */
    setDescription?: Dispatch<string>;
    /**
     * The setter for the {@link title} state. If not provided, title will be read-only.
     */
    setTitle?: Dispatch<string>;
    /**
     * Change appearance of the card to reflect job status.
     *
     * If set to IN_PROGRESS, display a progress icon to the left of the title.
     *
     * If set to ERROR, display an error icon and make the border of the card red.
     */
    status: DetailCardStatus;
    /**
     * An element displaying other high level info about the card, e.g. Bates range.
     */
    subHeader?: ReactNode;
    /**
     * The title of the card
     */
    title: string;
}

export const DetailCard: FC<DetailCardProps> & {
    BytesMetadata: FC<{ bytes: number }>;
    DateMetadata: FC<{ date: Date }>;
    ThreeDotMenu: FC<ThreeDotMenuProps>;
    VisibilityMetadata: FC<{ visible: boolean }>;
} = ({
    children,
    className,
    description,
    detailSection,
    everId,
    footer,
    headerButtons,
    headingElement,
    progressBar,
    setDescription,
    status = DetailCardStatus.DEFAULT,
    title,
    setTitle,
    subHeader,
}) => {
    // for the title field
    const [editTitle, setEditTitle] = useState(false);
    const headingButtonRef = useRef<HTMLDivElement>(null);
    const { buttonProps } = useButtonRole<HTMLDivElement>(headingButtonRef, {
        "aria-label": "Edit title",
        "aria-disabled": false,
        enabled: !!setTitle,
        onClick: useBrandedCallback(() => setEditTitle((val) => !val), []),
    });
    const { tooltipComponent, tooltipTargetProps } = useEllipsisTooltip<HTMLDivElement>({
        "aria-hidden": true,
        children: title,
        placement: [TooltipPlacement.BOTTOM],
        targetRef: headingButtonRef,
    });
    // destructure tooltipTargetProps for later use
    const {
        className: tooltipClassName,
        ref: tooltipRef,
        tabIndex: tooltipTabIndex,
    } = tooltipTargetProps;
    const allHeadingButtonRefs = useCombinedRef(headingButtonRef, tooltipRef);
    // This ref will be attached to the heading element, so we can get the width and apply it to
    // the TextField so that the two have the same width.
    const headingRef = useRef<HTMLHeadingElement>(null);

    // for the description field
    const [editDescription, setEditDescription] = useState(false);
    const descriptionRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        editDescription && descriptionRef?.current?.focus();
    }, [editDescription]);

    // statusIcon should be a CommonIcon.
    let statusIcon: ReactElement<IconProps> | null = null;
    if (status === DetailCardStatus.IN_PROGRESS) {
        statusIcon = <CommonIcon.Loading size={20} />;
    } else if (status === DetailCardStatus.ERROR) {
        statusIcon = <CommonIcon.Error size={20} />;
    }

    return (
        <section
            className={clsx("bb-detail-card", className, {
                "bb-detail-card--error": status === DetailCardStatus.ERROR,
            })}
            {...everIdProp(everId)}
        >
            {/* TODO: does the header element need to be a direct child of the enclosing <section>? */}
            <div className={"bb-detail-card__header"}>
                {/* TODO: update title and description once Renameable component is updated */}
                <div className={"bb-detail-card__title-bar"}>
                    <div className={"bb-detail-card__title"}>
                        {statusIcon}
                        {editTitle && setTitle ? (
                            <TextField
                                autoFocus={true}
                                className={"bb-detail-card__text-field-title"}
                                label={"card title"}
                                ellipsify={true}
                                hideLabel={true}
                                onBlur={() => setEditTitle((val) => !val)}
                                onChange={(e) => setTitle(e.target.value)}
                                value={title}
                                width={headingRef.current?.offsetWidth + "px"}
                                fontSize={TextFieldFontSize.LARGE}
                            />
                        ) : (
                            <Heading
                                element={headingElement}
                                className={clsx(
                                    "bb-detail-card__heading-title bb-ellipsis-overflow",
                                    {
                                        "bb-detail-card__heading-title--renameable": !!setTitle,
                                    },
                                )}
                                marginType={HeadingMargin.NONE}
                                ref={headingRef}
                            >
                                <div
                                    {...buttonProps}
                                    className={clsx(
                                        tooltipClassName,
                                        "bb-heading--small",
                                        "bb-ellipsis-overflow",
                                    )}
                                    tabIndex={tooltipTabIndex}
                                    ref={allHeadingButtonRefs}
                                >
                                    {title}
                                    {tooltipComponent}
                                </div>
                            </Heading>
                        )}
                    </div>
                    <div className={"bb-detail-card__header-buttons"}>{headerButtons}</div>
                </div>
                {!(description == null && setDescription == null) && (
                    <div className={"bb-detail-card__description"}>
                        {!description && !editDescription && setDescription ? (
                            <TextButton
                                children={
                                    <Icon.Plus size={16} color={EverColor.EVERBLUE_50}>
                                        Add description
                                    </Icon.Plus>
                                }
                                onClick={() => setEditDescription(true)}
                                size={ButtonSize.SMALL}
                            />
                        ) : (
                            <Renameable
                                disabled={!setDescription}
                                label={setDescription ? "Edit description" : "Description"}
                                height={TextFieldHeight.SMALL}
                                hideLabel={true}
                                onBlur={() => setEditDescription(false)}
                                onChange={
                                    setDescription
                                        ? (e) => setDescription(e.target.value)
                                        : undefined
                                }
                                onFocus={() => setEditDescription(true)}
                                textAlign={true}
                                value={description || ""}
                                width={TextFieldWidth.FULL}
                                fontSize={TextFieldFontSize.SMALL}
                                ref={descriptionRef}
                            />
                        )}
                    </div>
                )}
                {detailSection}
            </div>
            {progressBar}
            {subHeader && <div className={"bb-detail-card__sub-header"}>{subHeader}</div>}
            <div className={"bb-detail-card__body"}>{children}</div>
            {footer}
        </section>
    );
};

export interface ThreeDotMenuProps {
    /**
     * The content of the popup menu triggered by clicking on the
     * three-dot icon. Expects one or more PopoverMenu.Section elements.
     */
    children: ReactNode;
    /**
     * If true, show the popover.
     */
    show: boolean;
    /**
     * Function that sets the show state. You'll also want to call
     * {@code setShow(false)} in the {@code onClick()} handlers of
     * menu options, to close the menu
     */
    setShow: Dispatch<boolean>;
}

/**
 * A three-dot menu to display in the far right of headerButtons
 */
function ThreeDotMenu({ children, show, setShow }: ThreeDotMenuProps) {
    const iconButtonRef = useRef<HTMLButtonElement>(null);

    return (
        <>
            <IconButton
                aria-label={"More options"}
                ref={iconButtonRef}
                onClick={() => setShow(!show)}
            >
                <Icon.Dots size={20} />
            </IconButton>
            {/* place menu so as to cover as little of the card as possible */}
            <PopoverMenu
                children={children}
                show={show}
                setShow={setShow}
                trigger={iconButtonRef}
                placement={[
                    PopoverMenuPlacement.RIGHT,
                    PopoverMenuPlacement.RIGHT_START,
                    PopoverMenuPlacement.RIGHT_END,
                    PopoverMenuPlacement.LEFT_END,
                    PopoverMenuPlacement.LEFT,
                    PopoverMenuPlacement.LEFT_START,
                ]}
            />
        </>
    );
}
DetailCard.ThreeDotMenu = ThreeDotMenu;

// common metadata types, to show in DetailCard's detailSection

function BytesMetadata({ bytes }: { bytes: number }) {
    return <ItemDisplay.Bytes bytes={bytes} icon={<Icon.Database />} variant={TextVariant.SMALL} />;
}
DetailCard.BytesMetadata = BytesMetadata;

function DateMetadata({ date }: { date: Date }) {
    /* TODO: add datetime tooltip */
    return <ItemDisplay.Date date={date} variant={TextVariant.SMALL} />;
}
DetailCard.DateMetadata = DateMetadata;

/* TODO: add FileTypeMetadata for MPPL cards? */

function VisibilityMetadata({ visible }: { visible: boolean }) {
    return visible ? (
        <Icon.Eye size={16}>Visible on project homepage</Icon.Eye>
    ) : (
        <Icon.EyeOff size={16}>Hidden on project homepage</Icon.EyeOff>
    );
}
DetailCard.VisibilityMetadata = VisibilityMetadata;
