import clsx from "clsx";
import { IconButton } from "components/Button";
import * as Icon from "components/Icon";
import { Tooltip } from "components/Tooltip";
import { everIdProp } from "EverAttribute/EverId";
import React, { Dispatch, useRef } from "react";
import { EverIdProp } from "util/type";
import "./Pagination.scss";

export interface PaginatorProps extends EverIdProp {
    /**
     * An optional class name to apply to the paginator.
     */
    className?: string;
    /**
     * The aria-label to apply to the paginator. If there are multiple paginators or pagination
     * bars on the page, then provide a unique aria-label for each of them.
     */
    "aria-label"?: string;
    /**
     * Total number of rows.
     */
    numTotalRows: number;
    /**
     * The maximum number of rows per page.
     */
    pageSize: number;
    /**
     * The current page, using 1-based indexing.
     */
    currentPage: number;
    /**
     * The function that is called when the current page is changed through user action.
     */
    setCurrentPage: Dispatch<number>;
    /**
     * Whether the page info should be hidden. Generally this should only be true
     * during the initial load of the paginated element if the page and row info isn't known yet.
     * Defaults to false.
     */
    hidePageInfo?: boolean;
    /**
     * Whether buttons on the paginator should be disabled. Defaults to false.
     */
    disabled?: boolean;
}

/**
 * A component that displays the current page information as well as buttons for navigating
 * the pages of a paginated element.
 */
export function Paginator({
    className,
    "aria-label": ariaLabel = "Paginator",
    everId,
    numTotalRows,
    pageSize,
    currentPage,
    setCurrentPage,
    hidePageInfo = false,
    disabled = false,
}: PaginatorProps) {
    if (numTotalRows < 0 || pageSize <= 0 || currentPage <= 0) {
        hidePageInfo = true;
    }
    const numPages = Math.max(Math.ceil(numTotalRows / pageSize), 1);

    const firstRef = useRef<HTMLButtonElement>(null);
    const previousRef = useRef<HTMLButtonElement>(null);
    const nextRef = useRef<HTMLButtonElement>(null);
    const lastRef = useRef<HTMLButtonElement>(null);

    return (
        <nav
            className={clsx(className, "bb-paginator")}
            {...everIdProp(everId)}
            aria-label={ariaLabel}
        >
            {!hidePageInfo && (
                <div className={"bb-paginator__page-counter"}>
                    Page {currentPage} of {numPages}
                </div>
            )}
            <div className={"bb-paginator__buttons"}>
                <IconButton
                    ref={firstRef}
                    aria-label={"First page"}
                    disabled={disabled || !currentPage || currentPage === 1}
                    onClick={() => setCurrentPage(1)}
                >
                    <Icon.ChevronBarLeft size={20} />
                </IconButton>
                <Tooltip target={firstRef} aria-hidden={true}>
                    First page
                </Tooltip>
                <IconButton
                    ref={previousRef}
                    aria-label={"Previous page"}
                    disabled={disabled || !currentPage || currentPage === 1}
                    onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}
                >
                    <Icon.ChevronLeft size={20} />
                </IconButton>
                <Tooltip target={previousRef} aria-hidden={true}>
                    Previous page
                </Tooltip>
                <IconButton
                    ref={nextRef}
                    aria-label={"Next page"}
                    disabled={disabled || !currentPage || currentPage === numPages}
                    onClick={() => setCurrentPage(Math.min(currentPage + 1, numPages))}
                >
                    <Icon.ChevronRight size={20} />
                </IconButton>
                <Tooltip target={nextRef} aria-hidden={true}>
                    Next page
                </Tooltip>
                <IconButton
                    ref={lastRef}
                    aria-label={"Last page"}
                    disabled={disabled || !currentPage || currentPage === numPages}
                    onClick={() => setCurrentPage(numPages)}
                >
                    <Icon.ChevronBarRight size={20} />
                </IconButton>
                <Tooltip target={lastRef} aria-hidden={true}>
                    Last page
                </Tooltip>
            </div>
        </nav>
    );
}

export enum PaginationBarBorder {
    NONE = "none",
    FULL = "full",
    TOP = "top",
    BOTTOM = "bottom",
}

export interface PaginationBarProps extends Omit<PaginatorProps, "className" | "hidePageInfo"> {
    /**
     * An optional class name to apply to the pagination bar.
     */
    className?: string;
    /**
     * The border type of the pagination bar. Default {@link PaginationBarBorder.NONE}.
     */
    borderType?: PaginationBarBorder;
    /**
     * Whether the pagination bar itself (not the buttons inside it) should be tab-focusable.
     * Default false.
     */
    tabFocusable?: boolean;
    /**
     * Whether the page and rows info should be hidden. Generally this should only be true
     * during the initial load of the paginated element if the page and row info isn't known yet.
     * Defaults to false.
     */
    hidePageInfo?: boolean;
}

/**
 * A pagination bar that displays the current row range on the left and a {@link Paginator}
 * on the right.
 */
export function PaginationBar({
    className,
    borderType = PaginationBarBorder.NONE,
    tabFocusable = false,
    everId,
    ...paginatorProps
}: PaginationBarProps) {
    const pageStart = (paginatorProps.currentPage - 1) * paginatorProps.pageSize + 1;
    const pageEnd = Math.min(pageStart + paginatorProps.pageSize - 1, paginatorProps.numTotalRows);
    const rowCount = pageEnd <= 1 ? pageEnd : `${pageStart}-${pageEnd}`;
    return (
        <div
            className={clsx(className, "bb-pagination-bar", {
                "bb-pagination-bar--border": borderType === PaginationBarBorder.FULL,
                "bb-pagination-bar--top-border": borderType === PaginationBarBorder.TOP,
                "bb-pagination-bar--bottom-border": borderType === PaginationBarBorder.BOTTOM,
            })}
            tabIndex={tabFocusable ? 0 : undefined}
            {...everIdProp(everId)}
        >
            <div className={"bb-pagination-bar__row-counter"}>
                {!paginatorProps.hidePageInfo
                    && !!paginatorProps.numTotalRows
                    && `${rowCount} of ${paginatorProps.numTotalRows} total`}
            </div>
            <Paginator {...paginatorProps} />
        </div>
    );
}
